<template>
  <div>
    <template
      v-if="
        Object.keys(eventsAgenda).length > 0 && selectedCalendars.length > 0
      "
    >
      <v-row
        class="pl-2 agenda mx-0 my-2 primary-border"
        style="background-color: #1e1e1e"
        v-for="(day, i) in Object.keys(eventsAgenda)"
        :key="'day-' + i"
      >
        <v-col
          align="center"
          :cols="3"
          class="py-0 pt-1"
          :md="otherCalendars ? '3' : smaller ? 4 : '1'"
          style="text-transform: uppercase"
        >
          <v-btn
            class="pa-0"
            @click="dayView(new Date(day))"
            outlined
            style="
              color: white !important;
              height: 25px;
              width: 30px;
              border-color: transparent !important;
              padding-top: 20px !important;
            "
            elevation="0"
          >
            <span class="pb-2" style="font-size: 25px">{{
              $d(new Date(day), "day")
            }}</span>
          </v-btn>

          <p class="pb-0" style="white-space: nowrap">
            {{ $d(new Date(day), "agenda").substring(0, 3) }},
            {{ $d(new Date(day), "week") }}
          </p>
        </v-col>

        <v-col
          :cols="9"
          :md="otherCalendars ? '9' : smaller ? 8 : '10'"
          class="py-3"
        >
          <v-row
            v-for="(event, j) in eventsAgenda[day]"
            :key="'event-' + j"
            @click="showEvent({ event, nativeEvent: $event })"
            class="event"
            style="font-weight: bold"
          >
            <v-col class="py-0" cols="12">
              <p class="py-0 mb-1 no-wrap">
                <v-icon :color="event.color" x-small>mdi-circle</v-icon>
                {{ event.name }}
              </p>
              <p
                class="py-0 mb-1 ml-5 caption primary--text"
                style="font-weight: normal"
                v-if="event.h_start != null"
              >
                {{ event.h_start }} - {{ event.h_end }}
              </p>
              <p
                class="py-0 mb-1 ml-5 caption primary--text"
                style="font-weight: normal"
                v-else
              >
                {{ $t("events.allDay") }}
              </p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
    <template v-else-if="Object.keys(eventsAgenda).length > 0">
      <v-col class="py-0" cols="12">
        <h3 class="py-4 mb-1 no-wrap text-center primary--text">
          NO HAY CALENDARIOS SELECCIONADOS
        </h3>
      </v-col>
    </template>
    <template v-else-if="loaded">
      <v-col class="py-0" cols="12">
        <h3 class="py-4 mb-1 no-wrap text-center primary--text">
          NO HAY CITAS PROXIMAMENTE
        </h3>
      </v-col>
    </template>
    <v-menu
      v-model="selectedOpen"
      :close-on-content-click="false"
      :activator="selectedElement || null"
      :nudge-right="selectedElement ? 0 : $vuetify.breakpoint.xs ? 0 : 600"
      :nudge-bottom="selectedElement ? 0 : $vuetify.breakpoint.xs ? 200 : 400"
      offset-x
      id="menu"
      style="max-width: 450px"
    >
      <v-card
        :style="
          $vuetify.breakpoint.xsOnly
            ? 'min-width: 150px; max-width:350px; border: 1px solid ' +
              selectedEvent.color
            : 'min-width: 350px; max-width:450px; border: 1px solid ' +
              selectedEvent.color
        "
        flat
        v-if="selectedEvent"
        class="ma-0"
        id="eventView"
      >
        <v-toolbar dark>
          <v-toolbar-title>
            <v-icon class="mb-1 mr-2" size="14" :color="selectedEvent.color"
              >mdi-circle</v-icon
            >
            <v-tooltip bottom v-if="$vuetify.breakpoint.xs">
              <template v-slot:activator="{ on }">
                <span v-on="on">{{ selectedEvent.name }}</span>
              </template>
              {{ selectedEvent.name }}
            </v-tooltip>
            <span v-else>{{ selectedEvent.name }}</span>
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            class="mt-1"
            small
            v-if="!selectedEvent.url && selectedEvent.mail !== 'Reminder'"
            @click="transform()"
          >
            <v-icon>mdi-swap-vertical</v-icon>
          </v-btn>
          <v-btn
            icon
            v-if="selectedEvent.url"
            class="mt-1"
            small
            :href="selectedEvent.url"
          >
            <v-icon size="15">$edit</v-icon>
          </v-btn>
          <v-btn
            icon
            v-else-if="selectedEvent.start.length > 10 || selectedEvent.all_day"
            class="mt-1"
            small
            @click="$emit('edit', selectedEvent)"
          >
            <v-icon size="15">$edit</v-icon>
          </v-btn>
          <v-btn icon class="mt-1" small @click="removeEvent(selectedEvent)">
            <v-icon size="15">$delete</v-icon>
          </v-btn>

          <div class="close">
            <v-btn icon @click="selectedOpen = false" x-small>
              <v-icon small>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-toolbar>
        <v-card-text>
          <v-row align="center">
            <v-col cols="1" class="pr-0 pr-md-5 py-0">
              <v-icon class="pr-3" color="primary" size="30">$clock</v-icon>
            </v-col>
            <v-col class="py-0">
              <b
                v-html="formatEventDate(selectedEvent.start, selectedEvent.end)"
              ></b>
            </v-col>
          </v-row>
          <v-row v-if="selectedEvent.location && false" align="center">
            <v-col cols="1" class="pr-5 py-0">
              <v-icon class="pr-3" color="primary" size="30">$location</v-icon>
            </v-col>
            <v-col class="py-0">
              <b
                v-html="
                  selectedEvent.location.length > 5
                    ? selectedEvent.location
                    : '-'
                "
              ></b>
            </v-col>
          </v-row>
          <v-row v-if="false" align="center">
            <v-col cols="1" class="pr-5 py-0">
              <v-icon class="pr-3" color="primary" size="30">$customers</v-icon>
            </v-col>
            <v-col class="py-0">
              <span v-html="formatAttendees(selectedEvent.attendees)"></span>
            </v-col>
          </v-row>

          <v-row v-if="selectedEvent.customer" align="center">
            <v-col cols="1" class="pr-5 py-0">
              <v-icon class="pr-3" color="primary" size="30">$customers</v-icon>
            </v-col>
            <v-col class="py-0">
              <span v-html="selectedEvent.customer"></span>
            </v-col>
          </v-row>

          <v-row v-if="selectedEvent.phone" align="center">
            <v-col cols="1" class="pr-5 py-0">
              <v-icon size="30" class="pr-3" color="primary"> $phone </v-icon>
            </v-col>
            <v-col class="py-0">
              <span v-html="selectedEvent.phone"></span>
              <WhatsApp :phone_number="selectedEvent.phone" />
            </v-col>
          </v-row>
          <v-row v-if="selectedEvent.box" align="center">
            <v-col cols="1" class="pr-5 py-0">
              <v-icon size="30" class="pr-3" color="primary"> $boxes </v-icon>
            </v-col>
            <v-col class="py-0">
              <span v-html="selectedEvent.box.name"></span>
            </v-col>
          </v-row>
          <v-row v-if="selectedEvent.url" align="center">
            <v-col cols="1" class="pr-5 py-0">
              <v-icon color="primary">mdi-link-variant</v-icon>
            </v-col>
            <v-col class="py-0">
              <span>
                <a :href="selectedEvent.url">{{ $tc("appointment", 1) }}</a>
              </span>
            </v-col>
          </v-row>
          <v-row v-if="selectedEvent.description" align="center">
            <v-col cols="1" class="pr-5 py-0">
              <v-icon class="pr-3" color="primary">mdi-text</v-icon>
            </v-col>
            <v-col class="py-0">
              <span v-html="selectedEvent.description"></span>
            </v-col>
          </v-row>
          <p v-text="selectedEvent.mail"></p>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "AgendaView",
  props: {
    selectedCalendars: { type: Array, default: [] },
    selectedCalendar: { type: Number, default: 0 },
    selectedEmail: { type: String, default: "Quickink" },
    smaller: { type: Boolean, default: false },
  },
  watch: {
    selectedCalendars: {
      handler() {
        //console.log("rezise");
        this.loadEventsAgenda();
      },
      deep: true,
    },
  },
  data() {
    return {
      eventsAgenda: [],
      loaded: false,

      selectedEvent: null,
      selectedElement: null,
      selectedOpen: false,
    };
  },
  mounted() {
    this.loadEventsAgenda();
  },
  methods: {
    formatEventDate(startDateTime, endDateTime) {
      if (startDateTime.substring(0, 10) == endDateTime.substring(0, 10)) {
        if (startDateTime.length > 10) {
          return (
            this.$d(new Date(startDateTime.substring(0, 10))) +
            " " +
            startDateTime.substring(11, 16) +
            " - " +
            endDateTime.substring(11, 16)
          );
        } else {
          return this.$d(new Date(startDateTime));
        }
      } else {
        return (
          this.$d(new Date(startDateTime.substring(0, 10))) +
          " " +
          startDateTime.substring(11, 16) +
          " - " +
          this.$d(new Date(endDateTime.substring(0, 10))) +
          " " +
          endDateTime.substring(11, 16)
        );
      }
    },
    formatAttendees(attendees) {
      let attendee = null;
      let result = "";
      for (attendee of attendees) {
        result = result + attendee.email + "<br/>";
      }
      return result;
    },
    dayView(date) {
      this.type = "day";
      this.value = date;
      this.$emit("to_day", date);
    },

    ...mapActions("appointments", ["getEventCalendarAppointment"]),
    showEvent({ nativeEvent, event }) {
      console.log("TEST");
      if (nativeEvent) nativeEvent.stopPropagation();
      this.getEventCalendarAppointment(event.id).then((response) => {
        this.selectedEvent = {};
        const open = () => {
          if (response) {
            event.customer = response.customer.user.fullname;
            event.phone = response.customer.user.phone;
            event.box = response.available_dates[0].boxes_tattooer
              ? response.available_dates[0].boxes_tattooer.boxes
              : null;
          }
          this.$set(this, "selectedEvent", event);

          if (nativeEvent) this.selectedElement = nativeEvent.target;
          setTimeout(() => (this.selectedOpen = true), 10);
        };

        if (this.selectedOpen) {
          this.selectedOpen = false;
          setTimeout(open, 100);
        } else {
          open();
        }
      });
    },
    ...mapActions("calendar", ["getEventsAgenda"]),
    loadEventsAgenda() {
      this.getEventsAgenda({
        calendars: this.selectedCalendars,
        account: this.selectedCalendar,
        email: this.selectedEmail,
      }).then((events) => {
        this.eventsAgenda = events;
        this.loaded = true;
        console.log(this.eventsAgenda);
      });
    },
  },
};
</script>
<style lang="sass">
.agenda
  .event
    &:hover
      cursor: pointer
      background-color: var(--v-gris1-base)
.selected_calendar
  border: 1px var(-v-primary-base) solid
.v-calendar-category .v-calendar-category__column-header
  border-right: 0 !important
.v-calendar-category
  .v-calendar-daily_head-weekday,
  .v-calendar-daily_head-day-label
    display: none
</style>
